import React, { FC } from "react";
import styled from "styled-components";
import { FlexCol } from "../../../shared/globals";
import { SectionCardWrapperProps } from "../../types";
import { SectionBackgroundTypeEnum } from "../../../generated/graphql";

const SectionCardWrapper: FC<SectionCardWrapperProps> = ({
  children,
  isCollection,
  isSlider,
  isProduct,
  background,
}) => {
  return (
    <CardWrapper
      $isCollection={isCollection}
      $isSlider={isSlider}
      $background={background}
      $isProduct={isProduct}
    >
      {children}
    </CardWrapper>
  );
};

export default SectionCardWrapper;

const CardWrapper = styled(FlexCol)<{
  $isCollection?: boolean;
  $isSlider?: boolean;
  $background?: {
    color?: string;
    type: SectionBackgroundTypeEnum;
  };
  $isProduct?: boolean;
}>`
  position: relative;
  cursor: pointer;
  height: ${({ $isSlider }) => ($isSlider ? "calc(100% - 40px)" : "auto")};
  margin-bottom: ${({ $isSlider }) => ($isSlider ? "24px" : "0")};
  padding: 10px;

  &:hover {
    box-shadow: ${({ $isProduct, $background }) =>
      $isProduct
        ? $background?.type === SectionBackgroundTypeEnum.Color
          ? "0px 0px 10px 6px rgba(0,0,0,0.10)"
          : "0px 0px 8px 4px rgba(0,0,0,0.10)"
        : "none"};
  }

  background-color: ${({ $background }) =>
    $background?.type === SectionBackgroundTypeEnum.Color
      ? $background?.color
      : "transparent"};
`;
