import SectionCardWrapper from "@/templates/modern/elements/SectionCardWrapper";
import { FormattedDataType } from "@/templates/types";
import React from "react";
import AttributeValueCard from "./AttributeValueCard";

interface RenderAttributeCardProps {
  attributeValue: FormattedDataType["attributeValues"][0];
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
}

const RenderAttributeCard: React.FC<RenderAttributeCardProps> = ({
  attributeValue,
  section,
  isSliderSection,
}) => {
  return (
    <SectionCardWrapper
      isSlider={isSliderSection}
      data-test="attributeValue-card"
      background={section?.background}
    >
      <AttributeValueCard
        attributeValue={attributeValue}
        hideTitle={!section?.showItemName}
      />
    </SectionCardWrapper>
  );
};

export default RenderAttributeCard;
