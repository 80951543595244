import SectionCardWrapper from "@/templates/modern/elements/SectionCardWrapper";
import { FormattedDataType } from "@/templates/types";
import React from "react";
import BannerCard from "./BannerCard";

interface RenderBannerCardProps {
  banner: FormattedDataType["banners"][0];
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
}

const RenderBannerCard: React.FC<RenderBannerCardProps> = ({
  banner,
  section,
  isSliderSection,
}) => {
  const removeBorderRadiusDesktop =
    section.itemsPerRow === 1 && section.stretchToFullWidth;
  const removeBorderRadiusMobile = section.banners?.length === 1;

  return (
    <SectionCardWrapper
      isSlider={isSliderSection}
      data-test="banner-card"
      background={section?.background}
      removeBorderRadiusDesktop={removeBorderRadiusDesktop}
      removeBorderRadiusMobile={removeBorderRadiusMobile}
    >
      <BannerCard banner={banner} />
    </SectionCardWrapper>
  );
};

export default RenderBannerCard;
